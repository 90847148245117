<template>
  <div class="main">
    <amap></amap>
  </div>
</template>
<script>
import amap from '@/components/amap/index.vue'

export default {
  components: {
    amap
  }
}

</script>
<style lang="scss" scoped>
.main {
  width: 100vw;
  height: 100vh;
}
</style>
